<template>
  <v-card class="teros-elevation" :color="tableColor">
    <v-card-title class="px-5 text-uppercase v-title-cadastro"> 
      CÁLCULO DO INCREMENTO DE PESQUISADORES 
    </v-card-title>
    <v-card-text>
      <div class="d-flex pt-0">
        <v-menu offset-y dense max-width="160px">
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" class="table-v-action-button mr-2">
              <v-icon>mdi-file-export-outline</v-icon> Exportação
            </div>
          </template>
          <v-list>
            <v-list-item style="cursor: pointer" @click="handleExportCsv()">
              <v-list-item-title class="v-label-input">
                <v-icon left>mdi-file-delimited-outline</v-icon> Exportar CSV
              </v-list-item-title>
            </v-list-item>
            <v-list-item style="cursor: pointer" @click="handleExportXlsx()">
              <v-list-item-title class="v-label-input">
                <v-icon left>mdi-file-excel-outline</v-icon> Exportar XLSX
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-select
          style="max-width: 170px"
          class="mb-n1 mt-0 mr-3"
          prepend-icon="mdi-calendar"
          prefix="Ano Base:"
          :items="anosBase"
          v-model="anoBase"
          dense
          @change="doLoad()"
        ></v-select>
      </div>
    </v-card-text>
    <v-data-table
      class="mb-7"
      data-app
      :headers="headerStyle(colunas.detalhes)"
      :items="detalhes"
      :footer-props="{
        'items-per-page-options': [-1],
      }"
      hide-default-footer
    >
      <template v-slot:item="{ item, headers }">
        <tr class="table-v-tr" style="cursor: default">
          <td v-for="col in headers" v-bind:key="col.key">
            {{ col.format ? col.format(item[col.value]) : item[col.value] }}
          </td>
        </tr>
      </template>
    </v-data-table>
    <span class="m-3 d-block font-weight-bold"
      >Cálculo Ano:
      {{ anoAnterior && anoAnterior[0] ? anoAnterior[0].ano : "" }}</span
    >
    <v-data-table
      class="mb-7"
      data-app
      :headers="headerStyle(colunas.ano)"
      :items="anoAnterior"
      :footer-props="{
        'items-per-page-options': [-1],
      }"
      hide-default-footer
    >
      <template v-slot:item="{ item, headers }">
        <tr class="table-v-tr" style="cursor: default">
          <td v-for="col in headers" v-bind:key="col.key">
            {{ col.format ? col.format(item[col.value]) : item[col.value] }}
          </td>
        </tr>
      </template>
      <template v-slot:[`body.append`]>
        <tr class="table-v-tr" style="cursor: default">
          <th colspan="3"></th>
          <th>Total</th>
          <th>{{ totalAnoAnterior.media | toDouble }}</th>
          <th>{{ totalAnoAnterior.acrescimo }}</th>
          <th>{{ totalAnoAnterior.incentivo }}</th>
        </tr>
      </template>
    </v-data-table>
    <span class="m-3 d-block font-weight-bold"
      >Cálculo Ano: {{ anoAtual && anoAtual[0] ? anoAtual[0].ano : "" }}</span
    >
    <v-data-table
      class="mb-7"
      data-app
      :headers="headerStyle(colunas.ano)"
      :items="anoAtual"
      :footer-props="{
        'items-per-page-options': [-1],
      }"
      hide-default-footer
    >
      <template v-slot:item="{ item, headers }">
        <tr class="table-v-tr" style="cursor: default">
          <td v-for="col in headers" v-bind:key="col.key">
            {{ col.format ? col.format(item[col.value]) : item[col.value] }}
          </td>
        </tr>
      </template>
      <template v-slot:[`body.append`]>
        <tr class="table-v-tr" style="cursor: default">
          <th colspan="3"></th>
          <th>Total</th>
          <th>{{ totalAnoAtual.media | toDouble }}</th>
          <th>{{ totalAnoAtual.acrescimo }}</th>
          <th>{{ totalAnoAtual.incentivo }}</th>
        </tr>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters(['clientId']),
    resource: function () {
      return this.apiResource(
        `/v1/rh/clientes/${this.clientId}/incremento`
      );
    },
    detalhes: function () {
      return this.relatorio.detalhes.map((detalhe) => {
        detalhe.ano_base = `Ano Base ${detalhe.ano}`;
        return detalhe;
      });
    },
    anoAnterior: function () {
      return this.relatorio.anoAnterior;
    },
    totalAnoAnterior: function () {
      var total = this.relatorio.detalhes.find(
        (d) => this.relatorio.anoAnterior[0] && d.ano == this.anoAnterior[0].ano
      );
      return total ? total : {};
    },
    anoAtual: function () {
      return this.relatorio.anoAtual;
    },
    totalAnoAtual: function () {
      var total = this.relatorio.detalhes.find(
        (d) => this.relatorio.anoAtual[0] && d.ano == this.anoAtual[0].ano
      );
      return total ? total : {};
    },
    relatorioParaExportacao: function () {
      const relatoriosKey = ["detalhes", "anoAnterior", "anoAtual"];
      const relatorioExport = relatoriosKey.reduce((prev, key) => {
        if (!(key in this.relatorio)) {
          return prev;
        }

        const colunaKey = key === relatoriosKey[0] ? key : "ano";
        const header = this.colunas[colunaKey].map(({ text }) => text);
        const body = this.relatorio[key].map((row) => {
          return this.colunas[colunaKey].map(({ value, format }) => {
            return (format ? format(row[value]) : row[value]) || "";
          });
        });
        let totais = [];

        if (key !== "detalhes") {
          const totalAno =
            key === "anoAnterior" ? this.totalAnoAnterior : this.totalAnoAtual;
          totais = [
            "",
            "",
            "",
            "Total",
            this.$options.filters.toDouble(totalAno.media),
            totalAno.acrescimo,
            totalAno.incentivo,
          ];
        }

        return [...prev, [...header], ...body, totais, []];
      }, []);
      relatorioExport.pop();
      return relatorioExport;
    },
    anosBase: function () {
      var anoAtual = new Date().getFullYear();
      return [anoAtual, anoAtual - 1, anoAtual - 2, anoAtual - 3, anoAtual - 4];
    },
  },
  created: function () {
    this.anoBase = new Date().getFullYear();
    this.doLoad();
  },
  methods: {
    doLoad: function () {
      this.resource.get({query: `anoBase=${this.anoBase}`}).then((response) => {
        this.relatorio = response;
      });
    },
    headerStyle: function (headers) {
      return headers.map((h) => {
        h.class = `vuetify-table-header${this.getClient().isClient ? "-cli" : ""}`;
        return h;
      });
    },
    handleExportCsv: function () {
      this.exportCsv(this.relatorioParaExportacao);
    },
    handleExportXlsx: function () {
      this.exportXlsx(this.relatorioParaExportacao);
    },
  },
  data: function () {
    return {
      relatorio: {
        detalhes: [],
        anoAnterior: [],
        anoAtual: [],
      },
      anoBase: 2020,
      colunas: {
        detalhes: [
          { text: "Cálculo do Incremento", value: "ano_base" },
          {
            text: "Média Ano",
            value: "media",
            format: this.$options.filters.toDouble,
          },
          { text: "Acréscimo", value: "acrescimo" },
          { text: "Percentual de Utilização do Incentivo", value: "incentivo" },
          { text: "Mensagem", value: "comentario" },
        ],
        ano: [
          { text: "Nome Colaborador", value: "nome" },
          { text: "CPF", value: "cpf" },
          {
            text: "Data Admissão em P&D",
            value: "admissao_emped",
            format: this.$options.filters.toDate,
          },
          {
            text: "Data Desligamento em P&D",
            value: "demissao_emped",
            format: this.$options.filters.toDate,
          },
          {
            text: "Média Ano",
            value: "media",
            format: this.$options.filters.toDouble,
          },
          { text: "Cálculo Ano", value: "calculoAno" },
          { text: "Percentual de Utilização do Incentivo", value: "incentivo" },
        ],
      },
    };
  },
  watch: {
    clientId: function () {
      this.doLoad();
    },
  },
};
</script>